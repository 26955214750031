import ReactJson from "@microlink/react-json-view";
import { useQuery } from "@tanstack/react-query";
import {
  Accordion,
  Alert,
  Button,
  ButtonGroup,
  Col,
  Row,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import type { FnLog } from "../api/types";
import type { Fn, FnInfo } from "../bagel-api/types";
import { fetchChalkLog } from "../api/";
import { ResultBadge } from "../utils";
import Behavior from "../view-chalk/Behavior";
import FunctionCall from "../view-chalk/FunctionCall";
import HistoryItems from "../view-chalk/HistoryItems";
import ShowFunctions from "../view-chalk/ShowFunctions";
import PropertiesTable from "./PropertiesTable";

const chalkQueries = {
  get: (sessionId: string, momentId: string) => [
    "chalk_logs",
    { sessionId, momentId },
  ],
};

function RawLog({ rawLog }: { rawLog: object }) {
  return (
    <ReactJson
      src={rawLog}
      enableClipboard={false}
      displayObjectSize={false}
      displayDataTypes={false}
    />
  );
}

function FunctionReturn({ info, log }: { info: FnInfo; log: FnLog }) {
  const getAttemptedFn = (log: FnLog): Fn | null => {
    const functionCall = log.event_log["function_call"];

    if (!functionCall) return null;

    let args = {};
    try {
      args = JSON.parse(functionCall["arguments"]);
    } catch {
      return null;
    }

    return { name: functionCall["name"], args };
  };

  if (info.success) {
    return <FunctionCall selectedFn={info.selected_fn} />;
  } else {
    const attemptedFn = getAttemptedFn(log);

    const llmResponse = log.event_log["response"];

    return (
      <>
        <p className="mb-0">Got error:</p>
        <p className="ps-2">
          <code>{info.error_message}</code>
        </p>
        {attemptedFn && (
          <>
            <p className="mb-0">From attempted function call:</p>
            <p className="ps-2">
              <FunctionCall selectedFn={attemptedFn} />
            </p>
          </>
        )}
        {llmResponse && (
          <>
            <p className="mb-0">While processing response:</p>
            <p className="ps-2">
              <code>{llmResponse}</code>
            </p>
          </>
        )}
      </>
    );
  }
}

type HTTPPair = [
  {
    method: string;
    url: string;
    headers: Record<string, string>;
    body: string;
  },
  (
    | { status_code: string; headers: Record<string, string>; body: string }
    | { exception_type: string; exception: string | null }
  ),
];

function HTTPBody({
  headers,
  body,
}: {
  headers: Record<string, string>;
  body: string;
}) {
  if (headers["content-type"] && headers["content-type"].includes("json")) {
    return (
      <ReactJson
        src={JSON.parse(body)}
        name={false}
        enableClipboard={false}
        displayObjectSize={false}
        displayDataTypes={false}
      />
    );
  }
  return <div>{body}</div>;
}

function HTTPLog({ httpLog }: { httpLog: Array<HTTPPair> }) {
  return (
    <>
      {httpLog.map((pair, i) => {
        const [req, res] = pair;
        return (
          <Row key={`pair-${i}`}>
            <Col>
              <h4>Request</h4>
              <code>
                {req.method} {req.url}
              </code>
              <br />
              <HTTPBody headers={req.headers} body={req.body} />
            </Col>
            <Col>
              <h4>Response</h4>
              {"status_code" in res ? (
                <>
                  <code>{res.status_code}</code>
                  <br />
                  <HTTPBody headers={res.headers} body={res.body} />
                </>
              ) : (
                <>
                  Exception:{" "}
                  <code>
                    {res.exception_type}({res.exception})
                  </code>
                </>
              )}
            </Col>
          </Row>
        );
      })}
    </>
  );
}

function ChalkLog() {
  const { sessionId, momentId } = useParams();

  const { isPending, isError, data, error } = useQuery({
    queryKey: chalkQueries.get(sessionId!, momentId!),
    queryFn: () => fetchChalkLog(sessionId!, momentId!),
  });

  if (isPending) {
    return <div>Loading...</div>;
  } else if (isError) {
    return (
      <Alert variant="warning">
        Could not load chalk log: {error.message}.
      </Alert>
    );
  } else {
    return (
      <>
        <Row>
          <Col>
            <ButtonGroup>
              <Link
                to={`/chalk-logs/${sessionId}/${data.prev_moment}`}
                className={`btn btn-outline-primary bi-arrow-left ${
                  data.prev_moment || "disabled"
                }`}
              />

              <Button
                variant="outline-secondary"
                disabled
                style={{ color: "black" }}
              >
                {data.moment_key}
              </Button>

              <Link
                to={`/chalk-logs/${sessionId}/${data.next_moment}`}
                className={`btn btn-outline-primary bi-arrow-right ${
                  data.next_moment || "disabled"
                }`}
                title="next"
              />
            </ButtonGroup>
          </Col>

          <Col sm="2">
            <ButtonGroup>
              <Link
                to={`/chalk-playground/${sessionId}/${momentId}`}
                role="button"
                className="btn btn-outline-secondary bi-chat-left-text"
                title="Chalk playground"
              ></Link>

              <a
                href={`/api/chalk-logs/${sessionId}/${momentId}/export`}
                role="button"
                className="btn btn-outline-secondary bi-download"
                title="Export prompt"
              ></a>
            </ButtonGroup>
          </Col>
        </Row>

        <PropertiesTable chalkLog={data} />

        <Accordion
          defaultActiveKey={["result", "chalk", "raw-log"]}
          alwaysOpen
          className="mt-4"
        >
          <Accordion.Item eventKey="result">
            <Accordion.Header>
              <span className="me-4">Result</span>
              <ResultBadge result={data.result} />
            </Accordion.Header>

            <Accordion.Body>
              {"selected_fn" in data.raw_log.info ? (
                <FunctionReturn
                  info={data.raw_log.info}
                  //@ts-expect-error
                  log={data.raw_log.log}
                />
              ) : (
                <Behavior content={data.raw_log.info.generated_behavior} />
              )}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="chalk">
            <Accordion.Header>
              <i
                title="reverse chronological"
                style={{ textDecoration: "underline dotted" }}
                className="bi-sort-up"
              />{" "}
              Chalk
            </Accordion.Header>
            <Accordion.Body>
              <HistoryItems
                historyItems={data.raw_log.log.chalk}
                reversed={true}
              />
            </Accordion.Body>
          </Accordion.Item>

          {"functions" in data.raw_log.log && (
            <Accordion.Item eventKey="functions">
              <Accordion.Header>Functions</Accordion.Header>

              <Accordion.Body>
                <ShowFunctions functions={data.raw_log.log.functions} />
              </Accordion.Body>
            </Accordion.Item>
          )}

          {"http_log" in data.raw_log.log.event_log && (
            <Accordion.Item eventKey="http-requests">
              <Accordion.Header>HTTP Requests</Accordion.Header>

              <Accordion.Body>
                <HTTPLog httpLog={data.raw_log.log.event_log.http_log} />
              </Accordion.Body>
            </Accordion.Item>
          )}

          <Accordion.Item eventKey="raw-log">
            <Accordion.Header>Raw log</Accordion.Header>
            <Accordion.Body>
              <RawLog rawLog={data.raw_log} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
    );
  }
}

export default ChalkLog;
