import type { APIRequestSummary, Page } from "./types";
import { buildQueryString, getAPI } from "../api";

export async function fetchAPIRequests(
  searchParams: { startingAfter?: string | null; organization?: string | null },
  init?: {
    signal?: AbortSignal | null;
  },
): Promise<Page<APIRequestSummary>> {
  const urlQuery =
    "?" +
    buildQueryString({
      starting_after: searchParams.startingAfter,
      organization: searchParams.organization,
    });
  const url = "/api/api-requests" + urlQuery;

  return getAPI(url, init);
}
