import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import type { APIRequestSummary } from "./types";
import { formatDateTime } from "../utils";

function APIRequestsTable({
  apiRequests,
}: {
  apiRequests: Array<APIRequestSummary>;
}) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Created</th>
          <th>Organization</th>
          <th>Method</th>
          <th>Path</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {apiRequests.map((apiRequest, i) => {
          return (
            <tr key={i}>
              <td>
                <Link
                  to={`/api-requests/${apiRequest.organization}/${apiRequest.id}`}
                >
                  {formatDateTime(apiRequest.created)}
                </Link>
              </td>
              <td>{apiRequest.organization}</td>
              <td>{apiRequest.req_method}</td>
              <td>{apiRequest.req_path}</td>
              <td>{apiRequest.res_status_code}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default APIRequestsTable;
