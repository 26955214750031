import { ReactElement, useEffect, useReducer, useState } from "react";

import { useSearchParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import ToggleButton from "react-bootstrap/ToggleButton";

import { buildQueryString } from "../api";
import MomentsTable from "./MomentsTable";
import { ChalkLogsResponse } from "./types";

export class SearchError extends Error {
  field: string;

  constructor(msg: string, field: string) {
    super(msg);
    this.field = field;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, SearchError.prototype);
  }
}

export async function fetchChalkLogs(
  urlQuery: string,
  init?: {
    signal?: AbortSignal | null;
  },
): Promise<ChalkLogsResponse> {
  const url = "/api/chalk-logs" + urlQuery;

  const res = await fetch(url, init);

  if (res.status === 200) {
    return res.json();
  } else if (res.status === 400) {
    const body = await res.json();
    throw new SearchError(body.detail, body.field);
  } else throw new Error(`Unexpected response status ${res.status}`);
}

type ChalkLogSearchParams = {
  sessionKey: string | null;
  owner?: string | null;
  agentId?: string | null;
  projectId?: string | null;
  agentTemplate?: string | null;
  agentMetadata?: string | null;
};

type PaginationState = {
  onlyFailed: boolean;
  startingAfter: string | null;
  paginationHistory: Array<string | null>;
};
type PaginationAction =
  | { type: "push"; nextStartingAfter: string | null }
  | { type: "pop" }
  | { type: "reset" }
  | { type: "toggleOnlyFailed" };

function paginationReducer(
  state: PaginationState,
  action: PaginationAction,
): PaginationState {
  switch (action.type) {
    case "push":
      return {
        ...state,
        startingAfter: action.nextStartingAfter,
        paginationHistory: [...state.paginationHistory, state.startingAfter],
      };
    case "pop":
      return {
        ...state,
        startingAfter:
          state.paginationHistory[state.paginationHistory.length - 1],
        paginationHistory: state.paginationHistory.slice(0, -1),
      };
    case "reset":
      return {
        ...state,
        startingAfter: null,
        paginationHistory: [],
      };
    case "toggleOnlyFailed":
      return {
        onlyFailed: !state.onlyFailed,

        // we reset the pagination state when modifying the query.
        startingAfter: null,
        paginationHistory: [],
      };
  }
}

function PaginatedChalkLogs({
  title,
  search,
  onSearchSession = null,
  onSearchOwner = null,
  onSearchProject = null,
  onFetchStarted = null,
  onFetchSearchError = null,
  showSession = true,
  showOwner = true,
  showAgent = true,
}: {
  title: string | ReactElement;
  search: ChalkLogSearchParams;
  onSearchSession?: ((k: string) => void) | null;
  onSearchOwner?: ((k: string) => void) | null;
  onSearchProject?: ((k: string) => void) | null;
  onFetchStarted?: (() => void) | null;
  onFetchSearchError?: ((err: SearchError) => void) | null;
  showSession?: boolean;
  showOwner?: boolean;
  showAgent?: boolean;
}) {
  let [searchParams, setSearchParams] = useSearchParams();

  const [paginationState, dispatch] = useReducer(paginationReducer, {
    onlyFailed: searchParams.get("onlyFailed") === "true",
    startingAfter: null,
    paginationHistory: [],
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<ChalkLogsResponse | null>(null);

  const [refreshing, setRefreshing] = useState(false);

  const urlQuery =
    "?" +
    buildQueryString({
      starting_after: paginationState.startingAfter,
      only_failed: paginationState.onlyFailed,
      session_key: search.sessionKey,
      project_id: search.projectId,
      owner: search.owner,
      agent: search.agentId,
      agent_template: search.agentTemplate,
      agent_metadata: search.agentMetadata,
    });

  // when the search URL changes, request new data.
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setLoading(true);

    if (onFetchStarted) {
      onFetchStarted();
    }
    fetchChalkLogs(urlQuery, { signal })
      .then((data) => {
        setData(data);
        setError(null);
      })
      .catch((err: Error) => {
        if (!signal.aborted) setError(err);

        if (onFetchSearchError && err instanceof SearchError) {
          onFetchSearchError(err);
        }
      })
      .finally(() => {
        if (!signal.aborted) setLoading(false);
      });

    return () => {
      // cancel any request that may not have completed yet.
      abortController.abort();
    };
  }, [urlQuery, onFetchSearchError, onFetchStarted]);

  const doRefresh = () => {
    setRefreshing(true);
    fetchChalkLogs(urlQuery)
      .then((data) => {
        setData(data);
        setError(null);
      })
      .catch((err: Error) => {
        setError(err);
      })
      .finally(() => {
        setRefreshing(false);
      });
  };

  const toggleOnlyFailed = () => {
    setSearchParams((qs) => {
      qs.set("onlyFailed", (!paginationState.onlyFailed).toString());
      return qs;
    });

    dispatch({ type: "toggleOnlyFailed" });
  };

  const searchResults = () => {
    if (loading) {
      return (
        <div>
          <Spinner size="sm" /> Loading...
        </div>
      );
    } else if (!data || error) {
      return (
        <Alert variant="warning">
          Could not load chalk logs: {error?.message}
        </Alert>
      );
    } else if (data.items.length === 0) {
      return <Alert>No moments found.</Alert>;
    } else {
      return (
        <MomentsTable
          moments={data.items}
          onSearchSession={onSearchSession}
          onSearchOwner={onSearchOwner}
          onSearchProject={onSearchProject}
          showOwner={showOwner}
          showSession={showSession}
          showAgent={showAgent}
        />
      );
    }
  };

  return (
    <>
      <Row className="mt-4">
        <Col>
          <h3>{title}</h3>
        </Col>
        <Col xs={1}>
          <ButtonGroup>
            <ToggleButton
              type="checkbox"
              variant="outline-primary"
              checked={paginationState.onlyFailed}
              onClick={toggleOnlyFailed}
              title="Only show failed?"
              id="toggle-only-failed"
              value="1"
              className="bi-exclamation-circle"
            ></ToggleButton>
            <Button
              onClick={() => doRefresh()}
              disabled={paginationState.startingAfter !== null}
              title="Refresh"
            >
              {refreshing && <Spinner size="sm" />}
              {!refreshing && <i className="bi-arrow-clockwise" />}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>

      {searchResults()}

      <Pagination>
        <Pagination.First
          disabled={paginationState.startingAfter == null}
          onClick={() => dispatch({ type: "reset" })}
        />
        <Pagination.Prev
          disabled={paginationState.paginationHistory.length === 0}
          onClick={() => {
            dispatch({ type: "pop" });
          }}
        />
        <Pagination.Next
          disabled={!data || !data.next_page_starting_after}
          onClick={() => {
            dispatch({
              type: "push",
              nextStartingAfter: data!.next_page_starting_after,
            });
          }}
        />
      </Pagination>
    </>
  );
}

export default PaginatedChalkLogs;
