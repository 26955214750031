import { useOutletContext } from "react-router-dom";

import type { Organization } from "../api/types";
import ApiKeysTable from "../Users/ApiKeysTable";

function APIKeys() {
  const { organization }: { organization: Organization } = useOutletContext();

  return (
    <>
      <h2>API Keys</h2>

      <ApiKeysTable
        apiKeys={organization.api_keys}
        showCreatedBy={true}
        showAdd={false}
        onAdd={(_newApiKey) => null}
      />
    </>
  );
}

export default APIKeys;
