import React, { useState } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Alert, Button, Table } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import Form from "react-bootstrap/Form";

import type { Organization, Page, Project } from "../api/types";
import { getAPI, postAPI } from "../api";
import { ProjectSelectPresentation } from "../projects/ProjectSelect";

export const llmParamsQueries = {
  get: (organization: string) => ["llm-params", "get", organization],
};

type UpdatedParam = {
  endpoint_name: string;
  project_id: string | null;
  name: string;
  value: string;
};

type UpdatedSecret = {
  endpoint_name: string;
  project_id: string | null;
  name: string;
  value: string;
};

type EndpointLLMParams = {
  endpoint_name: string;
  default_params: Record<string, string>;
  default_secrets_redacted: Record<string, string>;
  project_params: Record<string, Record<string, string>>;
  project_secrets_redacted: Record<string, Record<string, string>>;
};

function fetchLLMParams(shortName: string): Promise<Page<EndpointLLMParams>> {
  const url = `/api/organizations/${shortName}/llm-params`;
  return getAPI(url);
}

function Rows({
  projectName = null,
  params,
  secrets,
}: {
  projectName?: string | null;
  params: Record<string, string>;
  secrets?: boolean;
}) {
  return (
    <>
      {Object.keys(params).map((paramName) => {
        return (
          <tr key={paramName}>
            {projectName && <td>{projectName}</td>}
            <th>
              {secrets && <i className="bi-lock" />} {paramName}
            </th>
            <td>{params[paramName]}</td>
          </tr>
        );
      })}
    </>
  );
}

function EndpointParams({
  projects,
  params,
  onAddParam,
  onAddSecret,
}: {
  projects: Array<Project>;
  params: EndpointLLMParams;
  onAddParam: (param: UpdatedParam) => void;
  onAddSecret: (param: UpdatedSecret) => void;
}) {
  const [newDefaultName, setNewDefaultName] = useState<string>("");
  const [newDefaultValue, setNewDefaultValue] = useState<string>("");
  const [isSecret, setIsSecret] = useState(false);

  const [newProjectProjectId, setNewProjectProjectId] = useState<string>("");
  const [newProjectName, setNewProjectName] = useState<string>("");
  const [newProjectValue, setNewProjectValue] = useState<string>("");

  const projectNamesById: Record<string, string> = {};
  projects.forEach((project) => (projectNamesById[project.id] = project.name));

  const addDefault = () => {
    if (isSecret)
      onAddSecret({
        project_id: null,
        endpoint_name: params.endpoint_name,
        name: newDefaultName,
        value: newDefaultValue,
      });
    else
      onAddParam({
        project_id: null,
        endpoint_name: params.endpoint_name,
        name: newDefaultName,
        value: newDefaultValue,
      });

    setNewDefaultName("");
    setNewDefaultValue("");
    setIsSecret(false);
  };

  const addProjectParam = () => {
    onAddParam({
      project_id: newProjectProjectId,
      endpoint_name: params.endpoint_name,
      name: newProjectName,
      value: newProjectValue,
    });

    setNewProjectProjectId("");
    setNewProjectName("");
    setNewProjectValue("");
  };

  return (
    <>
      <h3>Defaults</h3>

      <Table>
        <tbody>
          <Rows params={params.default_params} />
          <Rows params={params.default_secrets_redacted} secrets />
          <tr>
            <td>
              <Form.Control
                value={newDefaultName}
                onChange={(e) => setNewDefaultName(e.target.value)}
                placeholder="param name"
              />
            </td>
            <td>
              <Form.Control
                value={newDefaultValue}
                onChange={(e) => setNewDefaultValue(e.target.value)}
                placeholder="param value"
              />
            </td>
            <td>
              <Form.Check
                type="checkbox"
                label="Secret?"
                checked={isSecret}
                onChange={(e) => setIsSecret(e.target.checked)}
              />
            </td>
            <td>
              <Button
                className="bi-check"
                onClick={() => addDefault()}
                disabled={!newDefaultName || !newDefaultValue}
              />
            </td>
          </tr>
        </tbody>
      </Table>

      <h3>Per-project</h3>

      <Table>
        <tbody>
          {Object.keys(params.project_params).map((projectId) => {
            return (
              <React.Fragment key={projectId}>
                <Rows
                  projectName={projectNamesById[projectId] || projectId}
                  params={params.project_params[projectId] || {}}
                />
                <Rows
                  projectName={projectNamesById[projectId] || projectId}
                  params={params.project_secrets_redacted[projectId] || {}}
                  secrets
                />
              </React.Fragment>
            );
          })}
          <tr>
            <td>
              <ProjectSelectPresentation
                projectId={newProjectProjectId}
                projects={projects}
                onChange={setNewProjectProjectId}
              />
            </td>
            <td>
              <Form.Control
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
                placeholder="param name"
              />
            </td>
            <td>
              <Form.Control
                value={newProjectValue}
                onChange={(e) => setNewProjectValue(e.target.value)}
                placeholder="param value"
              />
            </td>
            <td>
              <Button
                className="bi-check"
                onClick={() => addProjectParam()}
                disabled={
                  !newProjectProjectId || !newProjectName || !newProjectValue
                }
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

function LLMParams() {
  const {
    shortName,
    organization,
  }: { shortName: string; organization: Organization } = useOutletContext();

  const { isPending, isError, data, error } = useQuery({
    queryKey: llmParamsQueries.get(shortName),
    queryFn: () => fetchLLMParams(shortName),
  });

  const queryClient = useQueryClient();

  const addParam = useMutation({
    mutationFn: ({ endpoint_name, ...update }: UpdatedParam) =>
      postAPI(
        `/api/organizations/${shortName!}/llm-params/${endpoint_name}/add`,
        update,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: llmParamsQueries.get(shortName),
      });
    },
  });

  const addSecret = useMutation({
    mutationFn: ({ endpoint_name, ...update }: UpdatedParam) =>
      postAPI(
        `/api/organizations/${shortName!}/llm-params/${endpoint_name}/add-secret`,
        { ...update },
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: llmParamsQueries.get(shortName),
      });
    },
  });

  if (isPending) {
    return <div>Loading...</div>;
  } else if (isError) {
    return (
      <Alert variant="warning">
        Could not load LLM params: {error.message}.
      </Alert>
    );
  } else {
    const paramsByEndpoint: Record<string, EndpointLLMParams> = {};
    data.items.forEach(
      (params) => (paramsByEndpoint[params.endpoint_name] = params),
    );

    // TODO: why isn't the fallback typechecked?
    const openaiParams = paramsByEndpoint["openai"] ?? {
      endpoint_name: "openai",
      default_params: {},
      default_secrets_redacted: {},
      project_params: {},
      project_secrets_redacted: {},
    };

    return (
      <>
        <p>
          Set parameters that should be passed to the LLM backend for this
          organization, with project-specific overrides. This is purely
          internal.
        </p>

        <h2>OpenAI</h2>

        <EndpointParams
          projects={organization.projects}
          params={openaiParams}
          onAddParam={(update) => addParam.mutate(update)}
          onAddSecret={(update) => addSecret.mutate(update)}
        />
      </>
    );
  }
}

export default LLMParams;
