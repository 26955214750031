import { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { Alert, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";

import type { Page } from "../api/types";
import { buildQueryString, getAPI } from "../api";
import DumpMetadata from "../Session/DumpMetadata";
import { SessionSummary } from "../Session/types";
import { formatDateTime } from "../utils";

export async function fetchSessions({
  startsAfter = null,
  projectId = null,
  sessionMetadata = null,
}: {
  startsAfter?: string | null;
  projectId?: string | null;
  sessionMetadata?: string | null;
}): Promise<Page<SessionSummary>> {
  return getAPI(
    "/api/sessions?" +
      buildQueryString({
        starting_after: startsAfter,
        project_id: projectId,
        metadata: sessionMetadata,
      }),
  );
}

function SessionsTable({ sessions }: { sessions: Array<SessionSummary> }) {
  if (sessions.length === 0) {
    return <Alert>No sessions found.</Alert>;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Created</th>
          <th>Session</th>
          <th>Project</th>
          <th>Metadata</th>
        </tr>
      </thead>
      <tbody>
        {sessions.map((session) => {
          return (
            <tr key={session.id}>
              <td>{formatDateTime(session.createdAt)}</td>
              <td>
                <Link to={`/sessions/${session.id}`}>{session.id}</Link>
              </td>
              <td>{session.project_name}</td>
              <td>
                <DumpMetadata metadata={session.metadata} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

function SessionsPage() {
  let [searchParams, setSearchParams] = useSearchParams();

  const [sessionMetadata, _setSessionMetadata] = useState<string | null>(
    searchParams.get("sessionMetadata"),
  );

  const [debouncedSessionMetadata] = useDebounce(sessionMetadata, 500);

  const setSessionMetadata = (sessionMetadata: string) => {
    setSearchParams((qs) => {
      qs.set("sessionMetadata", sessionMetadata);
      return qs;
    });
    _setSessionMetadata(sessionMetadata);
  };

  const { isPending, isError, data, error } = useQuery({
    queryKey: ["sessions", debouncedSessionMetadata],
    queryFn: () => fetchSessions({ sessionMetadata: debouncedSessionMetadata }),
  });

  let result: React.ReactElement;
  if (isPending) {
    result = <Spinner />;
  } else if (isError) {
    result = (
      <Alert variant="warning">Could not load sessions: {error.message}.</Alert>
    );
  } else {
    result = <SessionsTable sessions={data.items} />;
  }

  return (
    <>
      <h2>Sessions</h2>

      <h3>Filters</h3>

      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Session Metadata
        </Form.Label>
        <Col>
          <Form.Control
            type="search"
            value={sessionMetadata || ""}
            onChange={(e) => setSessionMetadata(e.target.value)}
          />
        </Col>
      </Form.Group>

      <div className="mt-4">{result}</div>
    </>
  );
}

export default SessionsPage;
