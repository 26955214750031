import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";

import DumpMetadata from "../Session/DumpMetadata";
import { SessionSummary } from "../Session/types";
import { formatDateTime } from "../utils";

async function fetchSessions(
  userId: string,
): Promise<{ items: Array<SessionSummary> }> {
  return fetch(`/api/users/${userId}/sessions`).then((res) => res.json());
}

function SessionsTable({ sessions }: { sessions: Array<SessionSummary> }) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Created</th>
          <th>Session</th>
          <th>Project</th>
          <th>Metadata</th>
        </tr>
      </thead>
      <tbody>
        {sessions.map((session) => {
          return (
            <tr key={session.id}>
              <td>{formatDateTime(session.createdAt)}</td>
              <td>
                <Link to={`/sessions/${session.id}`}>{session.id}</Link>
              </td>
              <td>{session.project_name}</td>
              <td>
                <DumpMetadata metadata={session.metadata} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

function Sessions({ userId }: { userId: string }) {
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState<Array<SessionSummary> | null>(null);

  useEffect(() => {
    setLoading(true);

    fetchSessions(userId)
      .then((sessions) => setSessions(sessions.items))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [userId]);

  if (loading) {
    return (
      <div>
        <Spinner size="sm" /> Loading...
      </div>
    );
  } else if (!sessions) {
    return <Alert variant="warning">Failed to load.</Alert>;
  } else {
    return (
      <>
        {sessions.length === 0 ? (
          <Alert variant="">This user has no sessions yet.</Alert>
        ) : (
          <SessionsTable sessions={sessions} />
        )}
      </>
    );
  }
}

export default Sessions;
