import PaginatedAPIRequests from "./PaginatedAPIRequests";

function ListAPIRequests() {
  return (
    <>
      <h1 className="mt-4">API Requests</h1>

      <PaginatedAPIRequests
        title="API Requests"
        search={{ organization: null }}
      />
    </>
  );
}

export default ListAPIRequests;
