import { Link } from "react-router-dom";

function AdminIndex() {
  return (
    <>
      <h1>Tools</h1>

      <ul>
        <li>
          <Link to="/organizations">Organizations</Link>
          <br />
          Manage organizations
        </li>

        <li>
          <Link to="/users">Users</Link>
          <br />
          Manage users and API keys
        </li>

        <li>
          <Link to="/sessions">Sessions</Link>
          <br />
          Search recent sessions
        </li>

        <li>
          <Link to="/chalk-logs">Chalk logs</Link>
          <br />
          Search recent chalk logs
        </li>

        <li>
          <Link to="/api-requests">API requests</Link>
          <br />
          View recent API requests
        </li>
      </ul>

      <h1>Docs</h1>

      <ul>
        <li>
          <Link to="/models">Models</Link>
          <br />
          View supported LLM models
        </li>
        <li>
          <a href="/docs/swagger">Bagel API docs (Swagger)</a>
        </li>
        <li>
          <a href="/docs/redoc">Bagel API docs (ReDoc)</a>
        </li>
        <li>
          <a href="/api/docs">Admin API docs (Swagger)</a>
        </li>
      </ul>
    </>
  );
}

export default AdminIndex;
