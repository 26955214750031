import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import { ChalkLog } from "../api/types";
import { formatDateTime, formatDuration, ResultBadge } from "../utils";

export function MomentsTable({
  moments,
  onSearchSession = null,
  onSearchOwner = null,
  onSearchProject = null,
  showSession = true,
  showOwner = true,
  showAgent = true,
}: {
  moments: Array<ChalkLog>;
  onSearchSession?: ((k: string) => void) | null;
  onSearchOwner?: ((k: string) => void) | null;
  onSearchProject?: ((k: string) => void) | null;
  showSession?: boolean;
  showOwner?: boolean;
  showAgent?: boolean;
}) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Created</th>
          {showSession && <th>Session</th>}
          {showOwner && <th>Owner</th>}
          {showAgent && <th>Agent</th>}
          <th>Project</th>
          <th>LLM</th>
          <th>Result</th>
          <th>Moment</th>
        </tr>
      </thead>
      <tbody>
        {moments.map((moment, _i) => {
          return (
            <tr key={`${moment.session_key}-${moment.moment_key}`}>
              <td>{formatDateTime(moment.created)}</td>
              {showSession && (
                <td>
                  {onSearchSession && (
                    <Button
                      variant="link"
                      style={{ textDecoration: "none" }}
                      onClick={() => onSearchSession(moment.session_key)}
                      className="bi-filter"
                    ></Button>
                  )}

                  <Link to={`/sessions/${moment.session_key}`}>
                    {moment.session_key}
                  </Link>
                </td>
              )}
              {showOwner && (
                <td>
                  {onSearchOwner && (
                    <Button
                      variant="link"
                      style={{ textDecoration: "none" }}
                      onClick={() => onSearchOwner(moment.owner)}
                      className="bi-filter"
                    ></Button>
                  )}

                  <Link to={`/users/${moment.owner}`}>{moment.owner}</Link>
                </td>
              )}
              {showAgent && (
                <td>
                  <Link
                    to={`/sessions/${moment.session_key}/agent/${moment.agent_id}`}
                  >
                    {moment.agent_id}
                  </Link>
                  <br />
                  {moment.agent_name}
                </td>
              )}
              <td>
                {moment.project_id && onSearchProject && (
                  <Button
                    variant="link"
                    style={{ textDecoration: "none" }}
                    onClick={() => onSearchProject(moment.project_id!)}
                    className="bi-filter"
                  ></Button>
                )}

                {moment.project_name}
              </td>
              <td>
                {moment.llm_endpoint} - {formatDuration(moment.llm_duration)}
              </td>
              <td>
                <ResultBadge result={moment.result} />
              </td>
              <td>
                <Link
                  to={`/chalk-logs/${moment.session_key}/${moment.moment_key}`}
                >
                  {moment.moment_key}
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default MomentsTable;
